import moment from "moment";

/* @ngInject */
export default class RawHrDataController {
  constructor($injector, $mdDialog, enrollmentId, studyId, timeZone, startTime) {
    this._$mdDialog = $mdDialog;
    this.enrollmentId = enrollmentId;
    this.studyId = studyId;
    this.timeZone = timeZone;
    this.startTime = startTime;
    this.loadingStatus = "notLoaded";
    this.endTime = this.getEndTime(startTime);
    this.features = $injector.get("Config").features;

    this._hrService = $injector.get("HeartRateService");
    this._excludeArtifactService = $injector.get("ExcludeArtifactService");
    this.hrData = [];
    this._loadData();
  }

  /// Public Functions ///

  cancel() {
    this._$mdDialog.cancel("Closed exclude heart rate data dialog");
  }

  save() {
    this._excludeArtifactService
      .save(this.enrollmentId)
      .then(() => {
        this._$mdDialog.hide();
      })
      .catch((error) => {
        const popupTitle = "Failed to save artifact regions.";
        const defaultMessage = `Unable to save changes to regions. Status Code: ${error.status}`;
        this._displayErrorMessage(popupTitle, defaultMessage, error);
      });
  }

  /// Private Functions ///
  getEndTime(startTime) {
    return moment(startTime).add(30, "minutes").valueOf();
  }

  _loadData() {
    this.loadingStatus = "loading";

    return this._hrService
      .getHeartRateData(this.startTime, this.endTime, this.studyId)
      .then((hrData) => {
        this.hrData = hrData;
        if (hrData.length > 0) {
          this.loadingStatus = "loaded";
        } else {
          this.loadingStatus = "none";
        }
      })
      .catch((error) => {
        this.loadingStatus = "error";
        console.error(error);
        this.hrData = [];
      });
  }

  _displayErrorMessage(popupTitle, defaultMessage, error) {
    // Format error message
    let errorMessage = defaultMessage;
    if (error?.data?.detail?.message) {
      errorMessage = error.data.detail.message.replace(/\n/g, "<br />");
    }
    // display error dialog
    return this._$mdDialog.show(
      this._$mdDialog
        .alert()
        .title(popupTitle)
        .htmlContent(
          `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
            `${errorMessage}</p>`
        )
        .ok("Ok")
        .multiple(true) // This must be included on the child mdDialog when there are nested mdDialogs
    );
  }
}
