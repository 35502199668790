/* eslint-env browser */
import React from "react";
import angular from "angular";
import ngAnimate from "angular-animate";
import ngAria from "angular-aria";
import ngCookies from "angular-cookies";
import ngFilter from "angular-filter";
import ngMaterial from "angular-material";
import ngMessages from "angular-messages";
import ngSanitize from "angular-sanitize";
import uirouter from "@uirouter/angularjs";

import components from "./components/index.js";
import dialogs from "./dialogs/index.js";
import pages from "./pages/index.js";
import shared from "./shared/index.js";
import AppController from "./app.controller.js";
import run from "./app.run.js";
import customizeTheme from "./customizeTheme.js";
import routing from "./routing.js";

import "../stylesheets/style.css";
import "angular-material/angular-material.min.css";
import "../stylesheets/style.styl";

if (typeof window !== "undefined") {
  window.React = React;
}

angular
  .module("app", [
    // vendor
    ngAria,
    ngAnimate,
    uirouter,
    ngMessages,
    ngFilter,
    ngMaterial,
    ngSanitize,
    ngCookies,

    // ours
    dialogs,
    components,
    pages,
    shared,
  ])
  .config(customizeTheme)
  .config(routing)
  .config(
    /* @ngInject */
    function configureApp($locationProvider, $httpProvider, $compileProvider) {
      // Angular prevents $http DELETE from sending a body. We needed to send a body with
      // itemAssignments delete requests. This allows us to send a body with delete requests.
      $httpProvider.defaults.headers.delete = {"Content-Type": "application/json;charset=utf-8"};

      /*
       * Disables the angular debugging tools.
       * Tools are required for Protractor but slow down the application.
       */
      if (window.globalConfig.disableDebugInfo) {
        $compileProvider.debugInfoEnabled(false);
      }

      $locationProvider.html5Mode({
        enabled: true,
        requireBase: false,
      });

      /*
       * Set up interceptors to automatically send and receive the Access-Token header.
       * See Interceptors service
       */
      $httpProvider.interceptors.push("Interceptors");
    }
  )
  .run(run)
  .controller("AppController", AppController)
  .constant("backendConfig", window.globalConfig);
